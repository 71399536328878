import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';

const routes = [
    {
        path: '',
        redirect: () => {
            return { path: '/login' };
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/bloqueados/:page?',
        name: 'bloqueados',
        component: () => import('../views/ProdutosBloqueados.vue'),
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('../views/Usuarios.vue'),
    },
    {
        path: '/gestores',
        name: 'gestores',
        component: () => import('../views/Gestores.vue'),
    },
    {
        path: '/lojas',
        name: 'lojas',
        component: () => import('../views/Lojas.vue'),
    },
    {
        path: '/tarefas',
        name: 'tarefas',
        component: () => import('../views/Tarefas.vue'),
    },
    {
        path: '/tarefas-validades',
        name: 'tarefas.validades',
        component: () => import('../views/TarefasSemValidade.vue'),
    },
    {
        path: '/tarefas/blocos',
        name: 'tarefas.blocos',
        component: () => import('../views/Blocos.vue'),
    },
    {
        path: '/rebaixas/:page?',
        name: 'rebaixas',
        component: () => import('../views/Rebaixas.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (user && to.name == 'login') {
        next({ path: '/bloqueados' });
    } else if (user) {
        next();
    } else if (to.name == 'login') {
        next();
    } else if (to.name == 'files') {
        next();
    } else {
        next({ path: '/login' });
    }

});

export default router;
