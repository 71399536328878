import { createStore } from 'vuex';
import authenticateStore from './authentication/index'
import bloqueadosStore from './bloqueados/index'

export default createStore({
    modules: {
        authenticate: authenticateStore,
        bloqueados: bloqueadosStore,
    },
    state: {},
    mutations: {},
    actions: {},
});
